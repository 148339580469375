import Stopwatch from '@/components/Stopwatch.vue'
import DocumentMixin from './DocumentMixin'
import api from '@/api'

export default {
  components: { Stopwatch },
  mixins: [DocumentMixin],
  data () {
    return {
      running: false,
      params: {
        docName: '',
        docId: '',
        text: ''
      },
      generationParams: {
        model: 'gpt-4o',
        temperature: 0.7,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      },
      messages: {
        system: '',
        user: '',
        assistant: ''
      }
    }
  },
  watch: {
    inputLink (value) {
      if (!value) return
      const parsedLink = this.parseLink(value)

      this.params.docName = ''
      this.params.docId = parsedLink.docId
    }
  },
  methods: {
    async generate () {
      this.running = true
      if (this.$refs.stopwatch) {
        this.$refs.stopwatch.reset()
        this.$refs.stopwatch.start()
      }
      this.messages.assistant = ''
      return api.post(`/generator/from-prompt`, {
        messages: this.messages,
        generationParams: this.generationParams
      })
        .then(({ data }) => {
          this.messages.assistant = data.message
          return data.message
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              this.$bvToast.toast(`У вас недостаточно разрешений для вызова генерации тестов`, {
                ...this.commonToaster, variant: 'danger'
              })
              break
            case 422:
              if (error.response.data === 'doc_not_specified') {
                this.$bvToast.toast(`Укажите идентификатор или название документа`, {
                  ...this.commonToaster, variant: 'danger'
                })
                break
              }
              this.$bvToast.toast(`Передано что-то невалидное`, {
                ...this.commonToaster, variant: 'danger'
              })
              break
            case 451:
              this.$bvToast.toast(`Сервис генерации недоступен`, {
                ...this.commonToaster, variant: 'danger'
              })
              break
            default:
              console.log(error)
              this.$bvToast.toast('Что-то пошло не так', {
                ...this.commonToaster, variant: 'danger'
              })
              break
          }
        })
        .finally(() => {
          if (this.$refs.stopwatch) {
            this.$refs.stopwatch.stop()
          }
          this.running = false
        })
    }
  }
}