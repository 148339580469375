<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PromptTab',
  props: {
    generationParams: {
      type: Object,
      required: true
    },
    messages: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION'])
  },
  data() {
    return {}
  }
}
</script>

<template>
  <b-tab title="Промпты" v-if="HAS_PERMISSION('generators_prompt')">
    <b-form-group>
      <b-input-group>
        <b-input-group-prepend is-text>
          System prompt
        </b-input-group-prepend>
        <b-form-textarea v-model.trim="messages.system" rows="2"/>
      </b-input-group>
    </b-form-group>

    <b-form-group>
      <b-input-group>
        <b-input-group-prepend is-text>
          User prompt
        </b-input-group-prepend>
        <b-form-textarea v-model.trim="messages.user" rows="15"/>
      </b-input-group>
    </b-form-group>


    <!--<b-input-group style="width: 200px; float: left">
      <b-input-group-prepend is-text>
        Max tokens
      </b-input-group-prepend>

      <b-form-input type="number" v-model="generationParams.max_tokens"/>
    </b-input-group>-->
    <b-form-group>
      <b-input-group style="width: 300px; float: left">
        <b-input-group-prepend is-text>
          Model
        </b-input-group-prepend>

        <b-form-select
            v-model="generationParams.model"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options=" [
              { value: 'gpt-4-1106-preview', text: 'gpt-4-1106-preview' },
              { value: 'gpt-4o', text: 'gpt-4o' },
              { value: 'gpt-4', text: 'gpt-4' },
              { value: 'gpt-3.5-turbo', text: 'gpt-3.5-turbo' },
            ]"
        />
      </b-input-group>

      <b-input-group style="width: 200px; float: left">
        <b-input-group-prepend is-text>
          Temperature
        </b-input-group-prepend>

        <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
            v-model="generationParams.temperature"
        />
      </b-input-group>

      <b-input-group style="width: 150px; float: left">
        <b-input-group-prepend is-text>
          Top_p
        </b-input-group-prepend>

        <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
            v-model="generationParams.top_p"
        />
      </b-input-group>

      <b-input-group style="width: 250px; float: left">
        <b-input-group-prepend is-text>
          frequency_penalty
        </b-input-group-prepend>

        <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
            v-model="generationParams.frequency_penalty"
        />
      </b-input-group>
      <b-input-group style="width: 250px; float: left">
        <b-input-group-prepend is-text>
          presence_penalty
        </b-input-group-prepend>

        <b-form-input
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
            v-model="generationParams.presence_penalty"
        />
      </b-input-group>
    </b-form-group>
  </b-tab>
</template>

<style scoped>

</style>